import ObjectExample from './modules/ObjectExample'

export default class Main {
  constructor() {
    this.init()
  }

  init() {
    window.addEventListener('load', () => {
      ObjectExample.init()
    })
  }
}

const main = new Main()
